<template>
  <app-form-autocomplete
    v-model="model"
    v-model:query="query"
    clearable
    item-title="properties.dealname"
    item-value="id"
    :items="items"
    label="Hubspot deal"
    :loading="loading"
    :no-data-text="noDataText"
    :required="required"
  >
    <template #item="{ item, props }">
      <v-list-item
        v-bind="props"
        :subtitle="item.id"
        :title="item.properties.dealname"
      />
    </template>
  </app-form-autocomplete>
</template>

<script lang="ts" setup>
import type { HubspotDeal } from "~~/model/hubspot.model";

const properties = defineProps<{
  modelValue?: string | null;
  required?: boolean;
}>();
const emit = defineEmits<{ "update:modelValue": [id?: string] }>();

const model = ref<HubspotDeal | undefined>();
const query = ref("");
const items = ref<HubspotDeal[]>([]);
const loading = ref(false);
const noDataText = computed(() =>
  query.value ? undefined : "Start typing to search for Hubspot deals",
);

watchDebounced(
  query,
  async (value) => {
    if (!value) {
      items.value = [];
      return;
    }

    try {
      loading.value = true;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      items.value = await $fetch("/api/hubspot/deals/search", {
        query: { query: value },
      });
    } finally {
      loading.value = false;
    }
  },
  { debounce: 150 },
);

watch(model, (deal) => emit("update:modelValue", deal?.id));

onMounted(async () => {
  if (!properties.modelValue) return;

  try {
    loading.value = true;
    model.value = await $fetch(`/api/hubspot/deals/${properties.modelValue}`);
  } finally {
    loading.value = false;
  }
});
</script>
